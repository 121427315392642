<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_search_group">
        <el-form
          :inline="true"
          size="min"
          :model="searchForm"
          ref="searchForm"
          @submit.native.prevent
        >
          <el-row>
            <el-col :md="10">
              <el-form-item label="账套简称：">
                <el-input
                  size="small"
                  v-model.trim="searchForm.acct_name"
                  clearable
                  placeholder="请填写账套简称"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  size="small"
                  type="primary"
                  icon="el-icon-search"
                  @click="getAcctsNow()"
                  class="vg_ml_16"
                  >查询</el-button
                >
                <el-button
                  type="info"
                  size="small"
                  class="vd_export"
                  @click="buttonRefresh()"
                  ><i class="el-icon-refresh-right"></i> 刷新</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <el-button
          type="primary"
          size="small"
          @click="addRow()"
          :disabled="!btn.add"
          ><i class="el-icon-plus"></i> 新增</el-button
        >
        <el-button
          type="danger"
          size="small"
          @click="doDelete()"
          :disabled="!btn.delete"
          ><i class="el-icon-delete"></i> 删除</el-button
        >
      </div>
      <el-row>
        <el-col :md="24">
          <el-table
            ref="multiTable"
            class="vg_pointer"
            :data="tableData"
            @selection-change="handleSelectionChange"
            @select="selectRows"
            @select-all="selectRows"
            @row-dblclick="dbClickJp"
            border
            v-loading="loadingFlag"
          >
            <el-table-column type="selection" width="48" align="center" />
            <el-table-column label="账套编号" prop="acct_no" />
            <el-table-column label="账套简称" prop="acct_name" />
            <el-table-column
              label="账套类型"
              prop="acct_type"
              :formatter="formatLeavType"
            />
            <el-table-column
              label="创建时间"
              prop="create_time"
              :formatter="formatDate"
            />
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination
            :totalPage="totalPage"
            @changePageSearch="changePageSearch"
            ref="pubPagination"
          ></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from "@api/request";
import { acctAPI } from "@api/modules/acct";
import pubPagination from "@/components/common/pubPagination";

export default {
  name: "AcctList",
  components: {
    pubPagination,
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        acct_name: "",
      },
      totalPage: 0,
      btn: {},
      loading: true,
      multiSelection: [],
      currentPage: 1,
      loadingFlag: true,
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getAcctsList();
    },
    // 获取账套信息
    getAcctsList() {
      const searchBody = this.searchForm;
      searchBody.page_no = this.currentPage;
      get(acctAPI.getAccts, searchBody)
        .then((res) => {
          if (res.data.code === 0) {
            this.loading = false;
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadingFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
          }
        })
        .catch((res) => {
          let mg = res.data.msg || "";
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    // 刷新
    buttonRefresh() {
      this.$refs.pubPagination.currentPage = 1;
      this.loadingFlag = true;
      this.searchForm = {
        acct_name: "",
      };
      this.currentPage = 1;
      this.initData();
    },
    // 查询方法
    getAcctsNow() {
      this.loadingFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.initData();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 判断是否可以删除
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump("/acct_edit", { perm_id: permId, form_id: row.acct_id });
    },
    //新增
    addRow() {
      const acctId = this.$route.query.perm_id;
      this.jump(`/acct_add?acct_add=${acctId}`);
    },
    // 分页查询
    changePageSearch(val) {
      this.loadingFlag = true;
      this.currentPage = val;
      this.initData();
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDay(row.create_time);
    },
    // 账套类型
    formatLeavType(row) {
      if (row.acct_type === 1) {
        return "一类";
      } else if (row.acct_type === 2) {
        return "二类";
      }
    },
    // 选未选中提示
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: "warning", message: "请至少选择一条数据！" });
      } else {
        this.mBox();
      }
    },
    //账套信息list删除提示
    mBox() {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const ids = [];
          this.multiSelection.map((item) => {
            ids.push(item.acct_id);
          });
          post(acctAPI.deleteAcctByIds, { acct_id_list: ids })
            .then((res) => {
              let mg = res.data.msg;
              if (res.data.code === 0) {
                let tp = "success";
                this.$message({ message: mg, type: tp });
                if (ids.length === this.tableData.length) {
                  if (this.currentPage > 1) {
                    this.currentPage = this.currentPage - 1;
                    this.$refs.pubPagination.currentPage = this.currentPage;
                  }
                }
                this.initData();
              } else {
                let tp = "error";
                this.$message({ message: mg, type: tp });
                this.initData();
              }
            })
            .catch((res) => {
              let mg = res.data.msg;
              let tp = "error";
              this.$message({ message: mg, type: tp });
              this.initData();
            });
        })
        .catch(() => {
          let mg = "取消删除！";
          let tp = "info";
          this.$message({ message: mg, type: tp });
        });
    },
  },
};
</script>

<style scoped>
</style>
